<template>
  <CCard class="moment">         
    <CCardBody class="p-0">
      <div v-if="parent === 'overview'" class="status_toggle">
        <b-switch v-model="momentData.is_active" @input="updateMomentTemplateStatus()" class="m-0" size="is-small"></b-switch>
      </div>      
      <div class="text-center">
        <div class="d-flex align-items-center justify-content-center moment_preview">        
          <div class="w-100 moment_preview_background">
            <img v-if="momentData.loyalty_moment_template_preview_image_id && !momentData.custom_moment" :src="apiBaseUrl + '/v1/common/cdn/file/image/loyalty-moment-preview/' + momentData.tag + '/' + momentData.loyalty_moment_template_preview_image_id + '/' + clientToken"/>
            <img v-else-if="momentData.loyalty_moment_template_preview_image_id && momentData.custom_moment" :src="apiBaseUrl + '/v1/common/cdn/file/image/loyalty-moment-preview-custom/' + momentData.tag + '/' + momentData.loyalty_moment_template_preview_image_id + '/' + clientToken"/>
            <img v-else-if="momentData.tag === 'custom_design'" :src="apiBaseUrl + '/v1/common/cdn/file/image/loyalty-moment-icon/common/own_design.png/' + clientToken"/>
            <img v-else :src="apiBaseUrl + '/v1/common/cdn/file/image/loyalty-moment-icon/common/soon.png/' + clientToken"/>
          </div>
          <div class="moment_preview_image">
            <img v-if="momentData.loyalty_moment_template_preview_image_id && !momentData.custom_moment" :src="apiBaseUrl + '/v1/common/cdn/file/image/loyalty-moment-preview/' + momentData.tag + '/' + momentData.loyalty_moment_template_preview_image_id + '/' + clientToken"/>
            <img v-else-if="momentData.loyalty_moment_template_preview_image_id && momentData.custom_moment" :src="apiBaseUrl + '/v1/common/cdn/file/image/loyalty-moment-preview-custom/' + momentData.tag + '/' + momentData.loyalty_moment_template_preview_image_id + '/' + clientToken"/>
            <loyaltyMomentPreviewPlaceholder v-else-if="momentData.tag === 'custom_design'" :placeholderImage="'own_design.png'" :content="$t('loyalty.Design_card_yourself')"/>
            <loyaltyMomentPreviewPlaceholder v-else :placeholderImage="'soon.png'" :content="$t('loyalty.Moment_preview_almost_available')"/>
          </div>
        </div>
        <div class="moment_info">
          <span v-line-clamp="1"><b>{{momentData.name}}</b></span>
          <div v-if="showMomentTag && momentData.is_available_for_external_api" class="moment_tag">
            <span>{{$t('loyalty.Tag_for_external_api')}}: <b>{{momentData.tag}}</b></span>
          </div>
        </div>
      </div>
      <div v-if="showEditButton" class="d-flex moment_actions">
        <CRow :gutters="true">
          <CCol v-if="showEditButton" cols="6" lg="6" md="6" sm="6" class="pt-0 pb-0">
            <CButton :to="'/loyalty/moments/template/' + momentData.loyalty_moment_template_id_external" class="w-100 m-0" color="primary">
              <span v-line-clamp="1"><i class="fa-solid fa-pen mr-1"></i>{{$t('common.Edit')}}</span>
            </CButton>
          </CCol>
        </CRow>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from 'axios'

import loyaltyMomentPreviewPlaceholder from '@/components/loyalty/loyaltyMomentPreviewPlaceholder.vue';

export default {
  name: 'loyaltyMomentCard',
  props: ['momentData', 'showEditButton', 'showMomentTag', 'parent'],
  components: {
    loyaltyMomentPreviewPlaceholder
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,
      updateApiPath: null,
      updateApiPathPrefix: null,
      updateApiMessage: null      
    }
  },
  methods: {
    updateMomentTemplateStatus() {
      this.momentData.custom_moment === false ? this.updateApiPathPrefix = 'loyalty/moments/template' : this.updateApiPathPrefix = 'loyalty/moments/template/custom';      

      if(this.momentData.is_active) {        
        this.updateApiPath = this.updateApiPathPrefix + '/' + this.momentData.loyalty_moment_template_id_external + '/activate';
        this.updateApiMessage = this.$t('loyalty.Moment_template_enabled');
      } else {
        this.updateApiPath = this.updateApiPathPrefix + '/' + this.momentData.loyalty_moment_template_id_external + '/deactivate';
        this.updateApiMessage = this.$t('loyalty.Moment_template_disabled');
      }

      axios.put(process.env.VUE_APP_API_URL + '/v1/' + this.updateApiPath)
      .then(res => {
        this.$buefy.toast.open({ message: this.updateApiMessage, type: 'is-success', duration: 2000 });
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      })
    }  
  },  
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
  }  
}
</script>