<template>
  <div class="moment_preview_placeholder">
    <div class="h-100 w-100 p-2_5 d-flex align-items-center justify-content-center">
      <div class="placeholder_content p-3 d-flex align-items-center justify-content-center flex-column">   
        <img :src="apiBaseUrl + '/v1/common/cdn/file/image/loyalty-moment-icon/common/' + placeholderImage + '/' + clientToken" class="mb-2"/>
        <span>{{content}}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'loyaltyMomentPreviewPlaceholder',
  props: ['placeholderImage', 'content'],
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null
    }
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
  }  
}
</script>